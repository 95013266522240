<template>
    <div class="rule-templates-view">
        <BaseHeader :title="$t('title')" />

        <div class="l-stack l-gap-4 rule-templates-view__container">
            <router-link :to="{ name: 'ruleCreate' }">
                <NotificationSettingsItem
                    :title="$t('noTemplate')"
                    :description="$t('noTemplateHint')"
                >
                    <template #iconRight>
                        <ArrowRightIcon width="24" height="24" />
                    </template>
                </NotificationSettingsItem>
            </router-link>

            <div class="l-stack l-gap-2">
                <h2>{{ $t('subTitle') }}</h2>

                <BaseInput
                    v-model="filter"
                    :debounce="400"
                    :placeholder="$t('searchPlaceholder')"
                    block
                >
                    <template #icon>
                        <SearchIcon width="16" height="16" />
                    </template>
                </BaseInput>
            </div>

            <transition-group
                name="templates-list"
                tag="div"
                class="templates-list"
            >
                <router-link
                    v-for="template in templatesFiltered"
                    :key="template.id"
                    :to="{
                        name: 'ruleCreate',
                        params: { template },
                    }"
                >
                    <NotificationSettingsItem
                        :title="template.name"
                        :description="template.description"
                    >
                        <template #icon>
                            <MonitorHeartRateIcon width="24" height="24" />
                        </template>
                    </NotificationSettingsItem>
                </router-link>
            </transition-group>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import { httpHelper, measurementHelper } from '@/utils'
import ArrowRightIcon from '../../icons/ArrowRightIcon'
import BaseHeader from '../../redesigned/BaseHeader'
import BaseInput from '../../redesigned/BaseInput'
import MonitorHeartRateIcon from '../../icons/MonitorHeartRateIcon'
import NotificationSettingsItem from '../../redesigned/NotificationSettingsItem'
import SearchIcon from '../../icons/SearchIcon'

export default {
    name: 'RuleTemplatesView',
    components: {
        ArrowRightIcon,
        BaseHeader,
        BaseInput,
        MonitorHeartRateIcon,
        NotificationSettingsItem,
        SearchIcon,
    },
    data() {
        return {
            filter: '',
            templates: [],
        }
    },
    computed: {
        ...mapState('location', ['locations']),
        templatesFiltered() {
            let templates = this.templates

            if (this.filter) {
                const filter = this.filter.toLowerCase()
                templates = templates.filter(
                    item =>
                        item.name.toLowerCase().includes(filter) ||
                        item.description.toLowerCase().includes(filter)
                )
            }

            return templates
        },
    },
    async mounted() {
        const { data } = await httpHelper.get('/alertrule-templates/templates/')
        this.templates = data.results
        this.templates.forEach(template => {
            template.conditions.conditions.forEach(item => {
                if (item.value === 'battery_percent') {
                    item.value = 'battery'
                }
            })

            template.description = this.getTemplateDescription(template)
        })
    },
    methods: {
        getLocationName(locationId) {
            return this.locations.find(location => location.id === locationId)
                ?.name
        },
        getTemplateDescription(template) {
            return template.conditions.conditions
                .map(condition => {
                    if (condition.type === 'geofence') {
                        return `${condition.position} ${this.getLocationName(
                            condition.location
                        )}`
                    }

                    const { value } = condition
                    const measurement = this.$t(`shared.measurements.${value}`)
                    const unit = measurementHelper.units[value] || ''
                    return `${measurement} ${condition.operator} ${condition.threshold} ${unit}`
                })
                .join(
                    ` ${this.$t(template.conditions.matchMode + 'MatchMode')} `
                )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "allMatchMode": "and",
        "anyMatchMode": "or",
        "noTemplate": "Continue without template",
        "noTemplateHint": "Configure everything by youself",
        "searchPlaceholder": "Search",
        "subTitle": "Choose a template",
        "title": "Rule templates"
    },
    "de": {
        "allMatchMode": "und",
        "anyMatchMode": "oder",
        "noTemplate": "Weiter ohne Vorlage",
        "noTemplateHint": "Alles selbst konfigurieren",
        "searchPlaceholder": "Durchsuchen",
        "subTitle": "Vorlage auswählen",
        "title": "Regel Vorlagen"
    },
    "fr": {
        "allMatchMode": "et",
        "anyMatchMode": "ou",
        "noTemplate": "Continuer sans modèle",
        "noTemplateHint": "Tout configurer par vous-même",
        "searchPlaceholder": "Parcourir",
        "subTitle": "Choisir un modèle",
        "title": "Modèles de règles"
    },
    "it": {
        "allMatchMode": "and",
        "anyMatchMode": "or",
        "noTemplate": "Ulteriore senza modello",
        "noTemplateHint": "Configurare tutto da soli",
        "searchPlaceholder": "Sfogliare",
        "subTitle": "Selezionare un modello",
        "title": "Modelli di regole"
    }
}
</i18n>

<style lang="scss" scoped>
.rule-templates-view {
    &__container {
        margin: 48px auto;
        max-width: 340px;
    }

    h2 {
        font-weight: 700;
        font-size: 20px;
        color: $color-text-new;
    }
}

.templates-list {
    position: relative;

    & > * {
        display: block;
        width: 100%;
        transition: all 0.2s;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &-leave-active {
        position: absolute;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
